import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import Sink from '../resources/images/new_connectors_logos/SinkIcon.svg'
import Source from '../resources/images/new_connectors_logos/SoureceIcon.svg'
import Searchicon from '../resources/images/Iconsearch.svg'
import { connectors } from '../helpers/connectors'
import Spacing from '../components/partials/Spacing'

const connectorsList = [
    { id: 1, option: 'Choose a connector', param: undefined },
    { id: 2, option: 'Amazon S3', param: 'aws-s3' },
    { id: 3, option: 'Azure Event Hubs', param: 'azure-event-hubs' },
    { id: 4, option: 'Google Cloud Storage', param: 'google-cloud-storage' },
    { id: 5, option: 'Google Cloud Pub/Sub', param: 'google-cloud-pub-sub' },
    { id: 6, option: 'Azure Data Lake', param: 'azure-data-lake' },
    { id: 7, option: 'FTP', param: 'ftp' },
    { id: 8, option: 'InfluxDB', param: 'influxdb' },
    { id: 9, option: 'HTTP', param: 'http' },
    { id: 10, option: 'JMS', param: 'jms' },
    { id: 11, option: 'Cassandra', param: 'cassandra' },
    { id: 12, option: 'MongoDB', param: 'mongodb' },
    { id: 13, option: 'Redis', param: 'redis' },
    { id: 14, option: 'MQTT', param: 'mqtt' },
    { id: 15, option: 'Azure CosmosDB', param: 'azure-cosmosdb' },
    { id: 16, option: 'Azure Service Bus', param: 'azure-service-bus' },
    { id: 17, option: 'Elasticsearch', param: 'elasticsearch' },
    { id: 18, option: 'Celonis', param: 'celonis' },
]

const kafkaConnectors = () => {
    const data = useStaticQuery(graphql`
        query connectorsQuery {
            allContentfulConnector {
                edges {
                    node {
                        systemImage {
                            title
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                        title
                        slug
                    }
                }
            }
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Connectors" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node
    // const connectorsListquery = data.allContentfulConnector.edges

    const [showFilter, setShowFilter] = useState(false)
    const [query, setQuery] = useState(null)
    const [filters, setFilters] = useState({
        technologyProvider: [],
        useCase: [],
        type: [],
    })

    const [selectedOption, setSelectedOption] = useState(connectorsList[0].option) // Set the initial selected option here

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value) // Update the selected option when the user changes the selection
    }

    const technologyProviders = [
        'AWS',
        'Azure',
        'Google',
        'Other/ None',
        'InfluxData',
        'Elastic',
        'Apache Foundation',
        'MongoDB',
        'Redis',
        'OASIS',
        'Celonis',
    ]
    // const useCases = [
    //     'Backup & Restore Kafka Topics',
    //     'Real-Time Analytics',
    //     'Event-Driven Applications',
    //     'Cloud Migration',
    //     'Business Automation',
    //     'Internet of Things',
    // ]

    const handleSearch = (value: string) => {
        if (filters.technologyProvider.length > 0 || filters.useCase.length > 0) {
            setFilters({
                technologyProvider: [],
                useCase: [],
                type: [],
            })
        }
        setQuery(value)
    }

    const handleFilterChange = (category, value) => {
        if (query !== null) {
            setQuery(null)
        }
        setFilters((prevFilters) => {
            const currentCategoryFilters = prevFilters[category]
            if (currentCategoryFilters.includes(value)) {
                return {
                    ...prevFilters,
                    [category]: currentCategoryFilters.filter((item) => item !== value),
                }
            } else {
                return {
                    ...prevFilters,
                    [category]: [...currentCategoryFilters, value],
                }
            }
        })
    }

    const isFilterChecked = (category, value) => filters[category].includes(value)

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Spacing />
            <section className='container-1 mx-auto kafka-connectors-intro'>
                <h1>
                    Open-source Kafka Connectors. <br /> Enterprise support.
                </h1>
                <p>
                    Lenses Kafka Connect Connectors are Apache 2.0 licensed and packed with <br />{' '}
                    features, offering more choice when modernizing apps and moving to <br /> the
                    cloud.
                </p>
                <div className='roll-button navbar-dl-button kafka-connectors-intro__buttons'>
                    <a
                        id='navbar-download'
                        style={{ fontSize: '1rem' }}
                        className='text-white mr-0 kafka-connectors-intro__buttons-orange'
                        href='/enterprise-support/'>
                        <span data-hover='Try Enterprise' style={{ marginTop: '2px' }}>
                            Try Enterprise
                        </span>
                    </a>
                    <a
                        rel={'noreferrer'}
                        id='navbar-download'
                        style={{ fontSize: '1rem', textAlign: 'center' }}
                        className='text-white mr-0 kafka-connectors-intro__buttons-transparent'
                        href='https://github.com/lensesio/stream-reactor/tree/master'
                        target={'_blank'}>
                        <span data-hover={'See GitHub'} style={{ textAlign: 'center' }}>
                            {/*<span style={{marginRight: '5px'}}>*/}
                            {/*<svg width="20" height="20" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)" fill="#1B1F23"/>*/}
                            {/*</svg>*/}
                            {/*</span> */}
                            See GitHub
                        </span>
                    </a>
                </div>
            </section>
            <div className='kafka-connectors-filter-parent'>
                <div className='kafka-connectors-filter-div1'>
                    <button
                        type='button'
                        onClick={() => setShowFilter((prev) => !prev)}
                        className='navbar-toggler mr-2 my-1 border-0 connector-filter-toggle-button'>
                        {' '}
                        <span className='sr-only'>Toggle navigation</span>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100px',
                                columnGap: '4px',
                            }}>
                            <span>&#x2630;</span>{' '}
                            <span style={{ position: 'relative', top: '2.5px', fontSize: '16px' }}>
                                Filters
                            </span>
                        </span>
                    </button>

                    <div
                        className='filter-mobile'
                        style={{
                            width: showFilter ? '300px' : 0,
                            display: showFilter ? 'block' : 'none',
                        }}>
                        <div className='filter-search'>
                            <input
                                type='text'
                                onChange={({ target }) => handleSearch(target.value)}
                                placeholder='Find a connector'
                            />
                            <span>
                                <img src={Searchicon} alt='' />
                            </span>
                        </div>

                        <div className='filter-category' style={{ marginBottom: '40px' }}>
                            <p>
                                <b>Technology Provider</b>
                            </p>
                            {technologyProviders.map((provider) => (
                                <div key={provider} className='checkbox-wrapper'>
                                    <input
                                        type='checkbox'
                                        id={provider}
                                        name={provider}
                                        value={provider}
                                        checked={isFilterChecked('technologyProvider', provider)}
                                        onChange={() =>
                                            handleFilterChange('technologyProvider', provider)
                                        }
                                    />
                                    <label htmlFor={provider}>{provider}</label>
                                </div>
                            ))}
                        </div>

                        <div className='filter-category' style={{ marginBottom: '20px' }}>
                            <p>
                                <b>Type</b>
                            </p>
                            {['Sink', 'Source'].map((type) => (
                                <div key={type} className='checkbox-wrapper'>
                                    <input
                                        type='checkbox'
                                        id={type}
                                        name={type}
                                        value={type}
                                        checked={isFilterChecked('type', type)}
                                        onChange={() => handleFilterChange('type', type)}
                                    />
                                    <label htmlFor={type}>{type}</label>
                                </div>
                            ))}
                        </div>

                        {/*<div className='filter-category'>*/}
                        {/*    <p>*/}
                        {/*        <b>Use case</b>*/}
                        {/*    </p>*/}
                        {/*    {useCases.map((useCase) => (*/}
                        {/*        <div key={useCase} className='checkbox-wrapper'>*/}
                        {/*            <input*/}
                        {/*                type='checkbox'*/}
                        {/*                id={useCase}*/}
                        {/*                name={useCase}*/}
                        {/*                value={useCase}*/}
                        {/*                checked={isFilterChecked('useCase', useCase)}*/}
                        {/*                onChange={() => handleFilterChange('useCase', useCase)}*/}
                        {/*            />*/}
                        {/*            <label htmlFor={useCase}>{useCase}</label>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '15px 0',
                            }}>
                            {(filters.technologyProvider.length !== 0 ||
                                filters.useCase.length !== 0) && (
                                <button
                                    onClick={() =>
                                        setFilters({
                                            technologyProvider: [],
                                            useCase: [],
                                            type: [],
                                        })
                                    }
                                    style={{
                                        display: 'flex',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        borderRadius: '15px',
                                        backgroundColor: 'orangered',
                                        padding: '4px 12px',
                                    }}
                                    className='button mr-0 kafka-connectors-intro__button'>
                                    Clear filter
                                </button>
                            )}
                        </div>
                    </div>

                    <div className='filter'>
                        <div className='filter-search'>
                            <input
                                type='text'
                                onChange={({ target }) => handleSearch(target.value)}
                                placeholder='Find a connector'
                            />
                            <span>
                                <img src={Searchicon} alt='' />
                            </span>
                        </div>

                        <div className='filter-category' style={{ marginBottom: '20px' }}>
                            <p>
                                <b>Technology Provider</b>
                            </p>
                            {technologyProviders.map((provider) => (
                                <div key={provider} className='checkbox-wrapper'>
                                    <input
                                        type='checkbox'
                                        id={provider}
                                        name={provider}
                                        value={provider}
                                        checked={isFilterChecked('technologyProvider', provider)}
                                        onChange={() =>
                                            handleFilterChange('technologyProvider', provider)
                                        }
                                    />
                                    <label htmlFor={provider}>{provider}</label>
                                </div>
                            ))}
                        </div>

                        <div className='filter-category' style={{ marginBottom: '20px' }}>
                            <p>
                                <b>Type</b>
                            </p>
                            {['Sink', 'Source'].map((type) => (
                                <div key={type} className='checkbox-wrapper'>
                                    <input
                                        type='checkbox'
                                        id={type}
                                        name={type}
                                        value={type}
                                        checked={isFilterChecked('type', type)}
                                        onChange={() => handleFilterChange('type', type)}
                                    />
                                    <label htmlFor={type}>{type}</label>
                                </div>
                            ))}
                        </div>

                        {/*<div className='filter-category'>*/}
                        {/*    <p>*/}
                        {/*        <b>Use case</b>*/}
                        {/*    </p>*/}
                        {/*    {useCases.map((useCase) => (*/}
                        {/*        <div key={useCase} className='checkbox-wrapper'>*/}
                        {/*            <input*/}
                        {/*                type='checkbox'*/}
                        {/*                id={useCase}*/}
                        {/*                name={useCase}*/}
                        {/*                value={useCase}*/}
                        {/*                checked={isFilterChecked('useCase', useCase)}*/}
                        {/*                onChange={() => handleFilterChange('useCase', useCase)}*/}
                        {/*            />*/}
                        {/*            <label htmlFor={useCase}>{useCase}</label>*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*</div>*/}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '15px 0',
                            }}>
                            {(filters.technologyProvider.length !== 0 ||
                                filters.useCase.length !== 0) && (
                                <button
                                    onClick={() =>
                                        setFilters({
                                            technologyProvider: [],
                                            useCase: [],
                                            type: [],
                                        })
                                    }
                                    style={{
                                        display: 'flex',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        borderRadius: '15px',
                                        backgroundColor: 'orangered',
                                        padding: '4px 12px',
                                    }}
                                    className='button mr-0 kafka-connectors-intro__button'>
                                    Clear filter
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className='kafka-connectors-filter-div2'>
                    {query !== null ||
                    filters.technologyProvider.length !== 0 ||
                    filters.useCase.length !== 0 ||
                    filters.type.length !== 0 ? (
                        <div className='connectors-wrap'>
                            {connectors
                                .filter((connector) =>
                                    ['Premium', 'Available'].includes(connector.availability),
                                )
                                .filter((connector) =>
                                    query !== null
                                        ? connector.name.toLowerCase().includes(query.toLowerCase())
                                        : connector,
                                )
                                .filter((connector) =>
                                    filters.technologyProvider.length > 0
                                        ? filters.technologyProvider.includes(connector.provider)
                                        : connector,
                                )
                                .filter((connector) =>
                                    filters.useCase.length > 0
                                        ? connector.features.find((feature) =>
                                              filters.useCase.includes(feature),
                                          )
                                        : connector,
                                )
                                .filter((connector) =>
                                    filters.type.length > 0
                                        ? filters.type.includes(connector.type)
                                        : connector,
                                )
                                .map((connector) => (
                                    <a key={connector.id} href={connector.link}>
                                        <div className='connector-card connector-card-hover'>
                                            {connector.availability === 'Premium' && (
                                                <span className='connector-card__chip'>
                                                    {connector.availability}
                                                </span>
                                            )}
                                            <img
                                                src={connector.logo}
                                                alt=''
                                                className='connector-card__logo'
                                            />
                                            <div className='connector-card__info'>
                                                <a href='' className='connector-card__name'>
                                                    {connector.name}
                                                </a>
                                                <p className='connector-card__descritpion'>
                                                    {connector.description}
                                                </p>
                                                <div className='connector-card__type-wrap'>
                                                    <div className='connector-card__type'>
                                                        {connector.type === 'Sink' ? (
                                                            <img
                                                                src={Sink}
                                                                className='connector-card__checkmark'
                                                                alt='Checkmark green'
                                                            />
                                                        ) : (
                                                            <img
                                                                src={Source}
                                                                className='connector-card__checkmark'
                                                                alt='Checkmark green'
                                                            />
                                                        )}

                                                        <span>{connector.type}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                        </div>
                    ) : (
                        <>
                            <div className='connectors-wrap'>
                                {connectors
                                    .filter((connector) =>
                                        ['Premium'].includes(connector.availability),
                                    )
                                    .map((connector) => (
                                        <a key={connector.id} href={connector.link}>
                                            <div className='connector-card connector-card-hover'>
                                                {connector.availability === 'Premium' && (
                                                    <span className='connector-card__chip'>
                                                        {connector.availability}
                                                    </span>
                                                )}
                                                <img
                                                    src={connector.logo}
                                                    alt=''
                                                    className='connector-card__logo'
                                                />
                                                <div className='connector-card__info'>
                                                    <a href='' className='connector-card__name'>
                                                        {connector.name}
                                                    </a>
                                                    <p className='connector-card__descritpion'>
                                                        {connector.description}
                                                    </p>
                                                    <div className='connector-card__type-wrap'>
                                                        <div className='connector-card__type'>
                                                            {connector.type === 'Sink' ? (
                                                                <img
                                                                    src={Sink}
                                                                    className='connector-card__checkmark'
                                                                    alt='Checkmark green'
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={Source}
                                                                    className='connector-card__checkmark'
                                                                    alt='Checkmark green'
                                                                />
                                                            )}
                                                            <span>{connector.type}</span>
                                                        </div>
                                                        {/*{connector.link.length > 0 && <a href={connector.link}>Learn more <i className='fa fa-chevron-right'></i></a>}*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                            </div>

                            <div className='connectors-divider divider' />

                            <div className='connectors-wrap'>
                                {connectors
                                    .filter((connector) =>
                                        ['Available'].includes(connector.availability),
                                    )
                                    .map((connector) => (
                                        <a key={connector.id} href={connector.link}>
                                            <div className='connector-card connector-card-hover'>
                                                {connector.availability === 'Premium' && (
                                                    <span className='connector-card__chip'>
                                                        {connector.availability}
                                                    </span>
                                                )}
                                                <img
                                                    src={connector.logo}
                                                    alt=''
                                                    className='connector-card__logo'
                                                />
                                                <div className='connector-card__info'>
                                                    <a href='' className='connector-card__name'>
                                                        {connector.name}
                                                    </a>
                                                    <p className='connector-card__descritpion'>
                                                        {connector.description}
                                                    </p>
                                                    <div className='connector-card__type-wrap'>
                                                        <div className='connector-card__type'>
                                                            {connector.type === 'Sink' ? (
                                                                <img
                                                                    src={Sink}
                                                                    className='connector-card__checkmark'
                                                                    alt='Checkmark green'
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={Source}
                                                                    className='connector-card__checkmark'
                                                                    alt='Checkmark green'
                                                                />
                                                            )}
                                                            <span>{connector.type}</span>
                                                        </div>
                                                        {/*{connector.link.length > 0 && <a href={connector.link}>Learn more <i className='fa fa-chevron-right'></i></a>}*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <section className='discover-connectors'>
                <h2>Try for yourself</h2>
                <div className='roll-button navbar-dl-button kafka-connectors-intro__buttons'>
                    <a
                        id='navbar-download'
                        style={{ fontSize: '1rem', backgroundColor: 'rgb(255, 148, 72)' }}
                        className='text-white mr-0 kafka-connectors-intro__buttons-orange'
                        href='/enterprise-support/'>
                        <span data-hover='Try Enterprise' style={{ marginTop: '2px' }}>
                            Try Enterprise
                        </span>
                    </a>
                    <a
                        rel={'noreferrer'}
                        id='navbar-download'
                        style={{ fontSize: '1rem', textAlign: 'center' }}
                        className='text-white mr-0 kafka-connectors-intro__buttons-transparent'
                        href='https://github.com/lensesio/stream-reactor/tree/master'
                        target={'_blank'}>
                        <span data-hover={'See GitHub'} style={{ textAlign: 'center' }}>
                            {/*<span style={{marginRight: '5px'}}>*/}
                            {/*<svg width="20" height="20" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*    <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)" fill="#1B1F23"/>*/}
                            {/*</svg>*/}
                            {/*</span> */}
                            See GitHub
                        </span>
                    </a>
                </div>
            </section>
            <section className='connectors-in-development'>
                <h2>Kafka Connectors in development</h2>
                {/*<p>*/}
                {/*    Our team is actively working on the next generation connectors. This is the list*/}
                {/*    with those, coming shortly in our Github repo.*/}
                {/*</p>*/}
                <div className='connectors-wrap'>
                    {connectors
                        .filter((connector) => ['In Development'].includes(connector.availability))
                        .map((connector) => (
                            <div key={connector.id} className='connector-card'>
                                {connector.availability === 'Premium' && (
                                    <span className='connector-card__chip'>
                                        {connector.availability}
                                    </span>
                                )}
                                <img src={connector.logo} alt='' className='connector-card__logo' />
                                <div className='connector-card__info'>
                                    <h3 className='connector-card__name'>{connector.name}</h3>
                                    <p className='connector-card__descritpion'>
                                        {connector.description}
                                    </p>
                                    <div className='connector-card__type-wrap'>
                                        <div className='connector-card__type'>
                                            {connector.type === 'Sink' ? (
                                                <img
                                                    src={Sink}
                                                    className='connector-card__checkmark'
                                                    alt='Checkmark green'
                                                />
                                            ) : (
                                                <img
                                                    src={Source}
                                                    className='connector-card__checkmark'
                                                    alt='Checkmark green'
                                                />
                                            )}
                                            <span>{connector.type}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </section>
            <Spacing />
            <section className='connectors-on-the-roadmap'>
                <h2>Kafka Connectors on the roadmap</h2>
                {/*<p>This is the list of connectors which will be next to enter in development</p>*/}
                <div className='connectors-wrap'>
                    {connectors
                        .filter((connector) => ['On the roadmap'].includes(connector.availability))
                        .map((connector) => (
                            <div key={connector.id} className='connector-card'>
                                {connector.availability === 'Premium' && (
                                    <span className='connector-card__chip'>
                                        {connector.availability}
                                    </span>
                                )}
                                <img src={connector.logo} alt='' className='connector-card__logo' />
                                <div className='connector-card__info'>
                                    <h3 className='connector-card__name'>{connector.name}</h3>
                                    <p className='connector-card__descritpion'>
                                        {connector.description}
                                    </p>
                                    <div className='connector-card__type-wrap'>
                                        <div className='connector-card__type'>
                                            {connector.type === 'Sink' ? (
                                                <img
                                                    src={Sink}
                                                    className='connector-card__checkmark'
                                                    alt='Checkmark green'
                                                />
                                            ) : (
                                                <img
                                                    src={Source}
                                                    className='connector-card__checkmark'
                                                    alt='Checkmark green'
                                                />
                                            )}
                                            <span>{connector.type}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </section>

            <section className='select-connector'>
                <h2>Select a connector to get started</h2>
                <p>
                    Choose an enterprise connector for Kafka Connect, give us your email, get a
                    demo.
                </p>
                <select
                    className='my-1 bg-white countries-dropdown'
                    name='connector'
                    id='connector'
                    value={selectedOption}
                    onChange={handleSelectChange}
                    style={{ width: '100%' }}>
                    {connectorsList.map((connector) => (
                        <option key={connector.id} value={connector.param}>
                            {connector.option}
                        </option>
                    ))}
                </select>
                <div className='roll-button navbar-dl-button kafka-connectors-intro__buttons'>
                    <a
                        id='navbar-download'
                        style={{ fontSize: '1rem' }}
                        className='text-white mr-0 kafka-connectors-intro__buttons-orange'
                        href={`/enterprise-support/?connector=${selectedOption}`}>
                        <span data-hover='Get started'>Get started</span>
                    </a>
                </div>
            </section>
        </Layout>
    )
}

export default kafkaConnectors
