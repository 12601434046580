import AWSS3 from '../resources/images/new_connectors_logos/AWS S3.svg'
import AzureEventHubs from '../resources/images/new_connectors_logos/Azure Event Hubs.svg'
import AzureDataLake from '../resources/images/new_connectors_logos/Azure Data Lake.svg'
import GoogleCloudStorage from '../resources/images/new_connectors_logos/Google Cloud Storage.svg'
import FTP from '../resources/images/new_connectors_logos/FTP.svg'
import Influx from '../resources/images/new_connectors_logos/InfluxDB.svg'
import HTTP from '../resources/images/new_connectors_logos/HTTP.svg'
import JMS from '../resources/images/new_connectors_logos/JMS.svg'
import Casandra from '../resources/images/new_connectors_logos/Cassandra.svg'
import MongoDB from '../resources/images/new_connectors_logos/MongoDB.svg'
import Redis from '../resources/images/new_connectors_logos/Redis.svg'
import MQTT from '../resources/images/new_connectors_logos/MQTT.svg'
import AzureCosmoDB from '../resources/images/new_connectors_logos/Azure CosmosDB.svg'
import AzureBlobStorage from '../resources/images/new_connectors_logos/Azure Blob Storage.svg'
import AzureServiceBus from '../resources/images/new_connectors_logos/Azure Service Bus.svg'
import Elasticsearch from '../resources/images/new_connectors_logos/Elasticsearch.svg'
import AWSAurora from '../resources/images/new_connectors_logos/AWS Aurora.svg'
import AWSLambda from '../resources/images/new_connectors_logos/AWS Lambda.svg'
import AWSDocumentDB from '../resources/images/new_connectors_logos/AWS DocumentDB.svg'
import AWSRedshift from '../resources/images/new_connectors_logos/AWS Redshift.svg'
import GoogleCloudPubSub from '../resources/images/new_connectors_logos/Google Cloud Pub-Sub.svg'
import JDBC from '../resources/images/new_connectors_logos/JDBC.svg'
import KafkaToKafka from '../resources/images/new_connectors_logos/Kafka To Kafka.svg'
import GoogleBigQuery from '../resources/images/new_connectors_logos/Google Big Query.svg'
import ApacheIceBerg from '../resources/images/new_connectors_logos/Apache Iceberg.svg'
import ApacheHudi from '../resources/images/new_connectors_logos/Apache Hudi.svg'
import Mulesoft from '../resources/images/new_connectors_logos/Mulesoft.svg'
import SAP from '../resources/images/new_connectors_logos/SAP.svg'
import Salesforce from '../resources/images/new_connectors_logos/Salesforce.svg'
import TIBCO from '../resources/images/new_connectors_logos/Tibco.svg'
import Celonis from '../resources/images/CelonisLogo.svg'

export const connectors = [
    {
        id: 1,
        name: 'Amazon S3',
        type: 'Source',
        availability: 'Premium',
        provider: 'AWS',
        features: [
            'Backup & Restore Kafka Topics',
            'Real-Time Analytics',
            'Event-Driven Applications',
        ],
        logo: AWSS3,
        // link: "https://docs.lenses.io/5.5/connectors/sources/s3sourceconnector/",
        link: '/kafka-connectors/kafka-to-aws-s3/',
        description: 'Apache Kafka S3 connectors to send data to and from Kafka to S3.',
    },
    {
        id: 2,
        name: 'Amazon S3',
        type: 'Sink',
        availability: 'Premium',
        provider: 'AWS',
        features: [
            'Backup & Restore Kafka Topics',
            'Real-Time Analytics',
            'Event-Driven Applications',
        ],
        logo: AWSS3,
        // link: "https://docs.lenses.io/5.5/connectors/sinks/s3sinkconnector/",
        link: '/kafka-connectors/kafka-to-aws-s3/',
        description: 'Apache Kafka S3 connectors to send data to and from Kafka to S3.',
    },
    {
        id: 3,
        name: 'Azure Event Hubs',
        type: 'Source',
        availability: 'Premium',
        provider: 'Azure',
        features: ['Cloud Migration', 'Event-Driven Applications'],
        logo: AzureEventHubs,
        link: 'https://docs.lenses.io/5.5/connectors/sources/azureeventhubs/',
        description:
            'Easily integrate Azure Event Hubs and Apache Kafka with this Azure Event Hubs Kafka Connector.',
    },
    {
        id: 4,
        name: 'Google Cloud Storage',
        type: 'Source',
        availability: 'Premium',
        provider: 'Google',
        features: ['Cloud Migration'],
        logo: GoogleCloudStorage,
        link: 'https://docs.lenses.io/5.5/connectors/sources/gcpstoragesourceconnector/',
        description: 'Easily send messages from Apache Kafka to Google Cloud Storage.',
    },
    {
        id: 5,
        name: 'Azure Data Lake',
        type: 'Sink',
        availability: 'Premium',
        provider: 'Azure',
        features: ['Real-time Analytics'],
        logo: AzureDataLake,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/datalakesinkconnector/',
        description: 'Send data from Kafka to Azure Data Lake. \n \n',
    },
    {
        id: 6,
        name: 'FTP',
        type: 'Source',
        availability: 'Available',
        provider: 'Other/ None',
        features: [],
        logo: FTP,
        link: 'https://docs.lenses.io/5.5/connectors/sources/ftpsourceconnector/',
        description: 'Monitor files on an FTP server and write data from FTP to Kafka.',
    },
    {
        id: 7,
        name: 'InfluxDB',
        type: 'Sink',
        availability: 'Available',
        provider: 'InfluxData',
        features: ['Real-time Analytics'],
        logo: Influx,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/influxsinkconnector/',
        description: 'An InfluxDB Kafka Connector to run queries for metrics and monitoring.',
    },
    {
        id: 8,
        name: 'HTTP',
        type: 'Sink',
        availability: 'Available',
        provider: 'Other/ None',
        features: ['Business Automation'],
        logo: HTTP,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/httpsinkconnector/',
        description: 'Trigger APIs with the HTTP Kafka connector to automate business processes.',
    },
    {
        id: 9,
        name: 'JMS',
        type: 'Source',
        availability: 'Available',
        provider: 'Other/ None',
        features: [],
        logo: JMS,
        link: 'https://docs.lenses.io/5.5/connectors/sources/jmssourceconnector/',
        description: 'Move messages with the Kafka JMS or JMS Kafka connectors.',
    },
    {
        id: 10,
        name: 'JMS',
        type: 'Sink',
        availability: 'Available',
        provider: 'Other/ None',
        features: [],
        logo: JMS,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/jmssinkconnector/',
        description: 'Move messages with the Kafka JMS or JMS Kafka connectors.',
    },
    {
        id: 11,
        name: 'Cassandra',
        type: 'Source',
        availability: 'Available',
        provider: 'Apache Foundation',
        features: ['Event-Driven Applications', 'Cloud Migration'],
        logo: Casandra,
        link: 'https://docs.lenses.io/5.5/connectors/sources/cassandrasourceconnector/',
        description:
            'Apache Kafka Cassandra connectors to send data to and from Kafka to Cassandra.',
    },
    {
        id: 12,
        name: 'Cassandra',
        type: 'Sink',
        availability: 'Available',
        provider: 'Apache Foundation',
        features: ['Event-Driven Applications', 'Cloud Migration'],
        logo: Casandra,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/cassandrasinkconnector/',
        description:
            'Apache Kafka Cassandra connectors to send data to and from Kafka to Cassandra.',
    },
    {
        id: 13,
        name: 'MongoDB',
        type: 'Sink',
        availability: 'Available',
        provider: 'MongoDB',
        features: [],
        logo: MongoDB,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/mongosinkconnector/',
        description: 'A Mongo Kafka connector to move data from Kafka to MongoDB.',
    },
    {
        id: 14,
        name: 'Redis',
        type: 'Sink',
        availability: 'Available',
        provider: 'Redis',
        features: [],
        logo: Redis,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/redissinkconnector/',
        description: 'A Redis Kafka connector to write data from Kafka to Redis.',
    },
    {
        id: 15,
        name: 'MQTT',
        type: 'Source',
        availability: 'Available',
        provider: 'OASIS',
        features: ['Internet of Things'],
        logo: MQTT,
        link: 'https://docs.lenses.io/5.5/connectors/sources/mqttsourceconnector/',
        description: 'MQTT source connector and MQTT sink connectors for Kafka.',
    },
    {
        id: 16,
        name: 'MQTT',
        type: 'Sink',
        availability: 'Available',
        provider: 'OASIS',
        features: ['Internet of Things'],
        logo: MQTT,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/mqttsinkconnector/',
        description: 'MQTT source connector and MQTT sink connectors for Kafka.',
    },
    {
        id: 17,
        name: 'Azure CosmosDB',
        type: 'SQL API',
        availability: 'Available',
        provider: 'Azure',
        features: ['Real-time Analytics'],
        logo: AzureCosmoDB,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/documentdbsinkconnector/',
        description: 'Export data from Apache Kafka to Azure CosmosDB.',
    },
    {
        id: 18,
        name: 'Elasticsearch 7',
        type: 'Sink',
        availability: 'Available',
        provider: 'Elastic',
        features: ['Business Automation', 'Real-time Analytics'],
        logo: Elasticsearch,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/elasticsinkconnector/',
        description: 'Write events from Kafka to Elasticsearch 7.',
    },
    {
        id: 19,
        name: 'Elasticsearch 8',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Elastic',
        features: [],
        logo: Elasticsearch,
        link: 'https://docs.lenses.io/5.5/connectors/sinks/elasticsinkconnector/',
        description: 'Write events from Kafka to Elasticsearch 8.',
    },
    {
        id: 20,
        name: 'Azure Data Lake',
        type: 'Source',
        availability: 'In Development',
        provider: 'Azure',
        features: ['Event-Driven Applications'],
        logo: AzureDataLake,
        link: '',
        description: 'Send data from Azure Data Lake to Apache Kafka.',
    },
    {
        id: 21,
        name: 'Google Cloud Storage',
        type: 'Source',
        availability: 'In Development',
        provider: 'Google',
        features: [],
        logo: GoogleCloudStorage,
        link: '',
        description: 'Easily send Google Cloud data to Apache Kafka.',
    },
    {
        id: 22,
        name: 'Google Cloud Pub/Sub',
        type: 'Source',
        availability: 'Premium',
        provider: 'Google',
        features: [],
        logo: GoogleCloudPubSub,
        link: 'https://docs.lenses.io/5.5/connectors/sources/gcppubsubsourceconnector/',
        description:
            'A Google Cloud Pub/Sub Kafka Connector to send data from Google Pub/Sub to Kafka.',
    },
    {
        id: 23,
        name: 'Google Cloud Pub/Sub',
        type: 'Sink',
        availability: 'Premium',
        provider: 'Google',
        features: [],
        logo: GoogleCloudPubSub,
        link: 'https://docs.lenses.io/5.5/connectors/sources/gcppubsubsourceconnector/',
        description: 'Two-way data integration with the Apache Kafka Google Pub/Sub connectors.',
    },
    {
        id: 24,
        name: 'Azure Event Hubs',
        type: 'Sink',
        availability: 'In Development',
        provider: 'Azure',
        features: [],
        logo: AzureEventHubs,
        link: '',
        description: 'Easily integrate Apache Kafka and Azure Event Hubs.',
    },
    {
        id: 25,
        name: 'JDBC',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'Oracle',
        features: [],
        logo: JDBC,
        link: '',
        description: 'Send data to and from JDBC and Kafka with the Kafka JDBC connectors.',
    },
    {
        id: 26,
        name: 'JDBC',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Oracle',
        features: [],
        logo: JDBC,
        link: '',
        description: 'Send data to and from JDBC and Kafka with the Kafka JDBC connectors.',
    },
    {
        id: 27,
        name: 'Azure Blob Storage',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'Azure',
        features: [],
        logo: AzureBlobStorage,
        link: '',
        description:
            'Send data to and from Azure Blob Storage and Kafka with the Kafka Azure Blob connectors.',
    },
    {
        id: 28,
        name: 'Azure Blob Storage',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Azure',
        features: [],
        logo: AzureBlobStorage,
        link: '',
        description:
            'Send data to and from Azure Blob Storage and Kafka with the Kafka Azure Blob connectors.',
    },
    {
        id: 29,
        name: 'Azure Service Bus',
        type: 'Source',
        availability: 'Premium',
        provider: 'Azure',
        features: [],
        logo: AzureServiceBus,
        link: 'https://docs.lenses.io/5.5/connectors/sources/azureservicebus/',
        description:
            'An Azure Service Bus Kafka Connector to send data from Azure Service Bus to Kafka.',
    },
    {
        id: 30,
        name: 'Azure Service Bus',
        type: 'Sink',
        availability: 'Premium',
        provider: 'Azure',
        features: [],
        logo: AzureServiceBus,
        link: 'https://docs.lenses.io/5.5/connectors/sources/azureservicebus/',
        description:
            'An Azure Service Bus Kafka Connector to send data from Kafka to Azure Service Bus.',
    },
    {
        id: 31,
        name: 'Kafka to Kafka',
        type: 'Replicator',
        availability: 'On the roadmap',
        provider: 'Apache Foundation',
        features: [],
        logo: KafkaToKafka,
        link: '',
        description:
            'Easily replicate Kafka topics from one cluster to another with the Kafka to Kafka replicator.',
    },
    {
        id: 32,
        name: 'Google BigQuery',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Google',
        features: [],
        logo: GoogleBigQuery,
        link: '',
        description:
            'Move data from Kafka to Google BigQuery with the Kafka BigQuery sink connector.',
    },
    {
        id: 33,
        name: 'MongoDB',
        type: 'Source',
        availability: 'On the roadmap',
        provider: '',
        features: [],
        logo: MongoDB,
        link: '',
        description: 'Move data from MongoDB to Kafka with the MongoDB Kafka source connector.',
    },
    {
        id: 34,
        name: 'Apache Iceberg',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'Apache Foundation',
        features: [],
        logo: ApacheIceBerg,
        link: '',
        description:
            'Send data to and from Apache Iceberg and Kafka with the Kafka Apache Iceberg connectors.',
    },
    {
        id: 35,
        name: 'Apache Iceberg',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Apache Foundation',
        features: [],
        logo: ApacheIceBerg,
        link: '',
        description:
            'Send data to and from Apache Iceberg and Kafka with the Kafka Apache Iceberg connectors.',
    },
    {
        id: 36,
        name: 'Apache Hudi',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'Apache Foundation',
        features: [],
        logo: ApacheHudi,
        link: '',
        description:
            'Send data to and from Apache Hudi and Kafka with the Kafka Apache Hudi connectors.',
    },
    {
        id: 37,
        name: 'Apache Hudi',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Apache Foundation',
        features: [],
        logo: ApacheHudi,
        link: '',
        description:
            'Send data to and from Apache Hudi and Kafka with the Kafka Apache Hudi connectors.',
    },
    {
        id: 38,
        name: 'AWS Aurora',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSAurora,
        link: '',
        description: 'Send data to and from AWS Aurora and Kafka with the Kafka Aurora connectors.',
    },
    {
        id: 39,
        name: 'AWS Aurora',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSAurora,
        link: '',
        description: 'Send data to and from AWS Aurora and Kafka with the Kafka Aurora connectors.',
    },
    {
        id: 40,
        name: 'AWS Redshift',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSRedshift,
        link: '',
        description:
            'Send data to and from AWS Redshift and Kafka with the Kafka Redshift connectors.',
    },
    {
        id: 41,
        name: 'AWS Redshift',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSRedshift,
        link: '',
        description:
            'Send data to and from AWS Redshift and Kafka with the Kafka Redshift connectors.',
    },
    {
        id: 42,
        name: 'AWS DocumentDB',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSDocumentDB,
        link: '',
        description:
            'Send data to and from AWS DocumentDB and Kafka with the Kafka DocumentDB connectors.',
    },
    {
        id: 43,
        name: 'AWS DocumentDB',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSDocumentDB,
        link: '',
        description:
            'Send data to and from AWS DocumentDB and Kafka with the Kafka DocumentDB connectors.',
    },
    {
        id: 44,
        name: 'MuleSoft',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'Salesforce',
        features: [],
        logo: Mulesoft,
        link: '',
        description: 'Send data to and from MuleSoft and Kafka with the Kafka MuleSoft connectors.',
    },
    {
        id: 45,
        name: 'MuleSoft',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Salesforce',
        features: [],
        logo: Mulesoft,
        link: '',
        description: 'Send data to and from MuleSoft and Kafka with the Kafka MuleSoft connectors.',
    },
    {
        id: 46,
        name: 'SAP',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'SAP',
        features: [],
        logo: SAP,
        link: '',
        description: 'Move data from SAP to Kafka with the SAP Kafka source connector.',
    },
    {
        id: 47,
        name: 'Salesforce',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'Salesforce',
        features: [],
        logo: Salesforce,
        link: '',
        description:
            'Send data to and from Salesforce and Kafka with the Kafka Salesforce connectors.',
    },
    {
        id: 48,
        name: 'Salesforce',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'Salesforce',
        features: [],
        logo: Salesforce,
        link: '',
        description:
            'Send data to and from Salesforce and Kafka with the Kafka Salesforce connectors.',
    },
    {
        id: 49,
        name: 'TIBCO',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'TIBCO',
        features: [],
        logo: TIBCO,
        link: '',
        description: 'Send data to and from TIBCO and Kafka with the Kafka TIBCO connectors.',
    },
    {
        id: 50,
        name: 'TIBCO',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'TIBCO',
        features: [],
        logo: TIBCO,
        link: '',
        description: 'Send data to and from TIBCO and Kafka with the Kafka TIBCO connectors.',
    },
    {
        id: 51,
        name: 'AWS Lambda',
        type: 'Source',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSLambda,
        link: '',
        description:
            'Send data to and from AWS Lambda and Kafka with the Kafka AWS Lambda connectors.',
    },
    {
        id: 52,
        name: 'AWS Lambda',
        type: 'Sink',
        availability: 'On the roadmap',
        provider: 'AWS',
        features: [],
        logo: AWSLambda,
        link: '',
        description:
            'Send data to and from AWS Lambda and Kafka with the Kafka AWS Lambda connectors.',
    },
    {
        id: 53,
        name: 'Celonis',
        type: 'Sink',
        availability: 'Available',
        provider: 'Other/ None',
        features: ['Event-Driven Applications', 'Cloud Migration'],
        logo: Celonis,
        link: '/kafka-connectors/kafka-to-celonis/',
        description: 'Send streaming data to your process intelligence platform.',
    },
]
